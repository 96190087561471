type ChainName = {
  [chainId: number]: string; // Maps chain IDs to chain names
};

const chainNames: ChainName = {
  1: 'ethereum',
  56: 'bsc',
  8453: 'base',
  42161: 'arbitrum',
};

export default chainNames;

/**
 * Checks if the given address is the native token address.
 *
 * @param address - The Ethereum address to check.
 * @returns A boolean indicating if the address is the native token address.
 */
export const isNativeTokenHelper = (address: string): boolean => {
  const nativeTokenAddress = '0x0000000000000000000000000000000000000000';
  return address.toLowerCase() === nativeTokenAddress.toLowerCase();
};

export const isSameChain = (srcChainId: number, destChainId: number) => {
  if (srcChainId === destChainId) {
    return true;
  } else {
    return false;
  }
};
