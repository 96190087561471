import wrappedNativeCurrencies from '@/config/wrappedNativeCurrencies';
import chainNames, { isNativeTokenHelper } from '@/utils';
import { useMemo } from 'react';
import useSWR from 'swr';

type TokenPriceResponse = {
  data: {
    value: number; // USD price of the token
  };
  success: boolean;
};

const fetcher = async (url: string, chainId: number): Promise<number> => {
  const response = await fetch(url, {
    method: 'GET',
    headers: {
      'X-API-KEY': process.env.NEXT_PUBLIC_BIRDEYE_API_KEY!,
      'x-chain': chainNames[chainId],
    },
  });
  const data: TokenPriceResponse = await response.json();
  if (!data.success) throw new Error('Failed to fetch token price');
  return data.data.value; // Returning only the token price in USD
};

/**
 * Custom hook to fetch token prices and calculate USD values for tokenIn and tokenOut.
 * @param tokenIn Address of the input token
 * @param tokenOut Address of the output token
 * @param tokenInAmount Amount of tokenIn
 * @param tokenOutAmount Amount of tokenOut
 * @returns Token prices and calculated USD values
 */
export const useTokenPrices = (
    tokenIn: string,
    tokenOut: string,
    tokenInAmount: number,
    tokenOutAmount: number,
    srcChainId: number,
    destChainId: number
  ) => {
    const { data, error } = useSWR(
      () => [
        `https://public-api.birdeye.so/defi/price?address=${
          isNativeTokenHelper(tokenIn)
            ? wrappedNativeCurrencies[srcChainId]
            : tokenIn
        }`,
        `https://public-api.birdeye.so/defi/price?address=${
          isNativeTokenHelper(tokenOut)
            ? wrappedNativeCurrencies[destChainId]
            : tokenOut
        }`,
      ],
      async ([tokenInUrl, tokenOutUrl]: [string, string]) => {
        const [tokenInPrice, tokenOutPrice] = await Promise.all([
          fetcher(tokenInUrl, srcChainId),
          fetcher(tokenOutUrl, destChainId),
        ]);
        return { tokenInPrice, tokenOutPrice };
      }
    );
  
    const isLoading = !data && !error;
    const isError = !!error;
  
    // Calculate USD values only if prices are available
    const { tokenInUSD, tokenOutUSD } = useMemo(() => {
      if (!data) {
        return {
          tokenInUSD: 0,
          tokenOutUSD: 0,
        };
      }
      
      const srcInUSD = data.tokenInPrice * tokenInAmount;
      const destOutUSD = data.tokenOutPrice * tokenOutAmount;
      return {
        tokenInUSD: srcInUSD,
        tokenOutUSD: destOutUSD,
      };
    }, [data, tokenInAmount, tokenOutAmount]);
  
    return {
      tokenInPrice: data?.tokenInPrice ?? null,
      tokenOutPrice: data?.tokenOutPrice ?? null,
      tokenInUSD,
      tokenOutUSD,
      isLoading,
      isError,
    };
  };