
type WrappedNativeCurrency = {
    [key in number]: string; // Ensures only specified chain IDs are valid
  };
const wrappedNativeCurrencies: WrappedNativeCurrency = {
    1: "0xC02aaA39b223FE8D0A0e5C4F27eAD9083C756Cc2", // WETH on Ethereum
    56: "0xBB4CdB9CBd36B01bD1cBaEBF2De08d9173bc095c", // WBNB on BSC
    8453: "0x4200000000000000000000000000000000000006", // WETH on Base
    42161: "0x82AF49447d8a07e3bd95BD0d56f35241523FBAB1", // WETH on Arbitrum
  };
  
  export default wrappedNativeCurrencies;