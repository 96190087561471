/* eslint-disable @typescript-eslint/no-unused-vars */
'use client';

import { motion } from 'framer-motion';
import { Settings } from 'lucide-react';
import { Input } from '../ui/input';
import { ConnectButton } from '../connect-button';
import { useAccount } from 'wagmi';
import { useAppDispatch, useAppSelector } from '@/hooks/useStore';
import { setSwapSetting } from '@/store/slices/controllerSlice';
import { WalletBalance } from './balance';
import { useCallback, useEffect, useMemo, useState } from 'react';
import { setInputAmount } from '@/store/slices/swapInputSlice';
import { useQuery } from '@tanstack/react-query';
import axios, { AxiosError } from 'axios';
import { SkeletonBox } from '../ui/skeleton';
import { formatUnits, parseUnits } from 'viem';
import { convertNumbThousand, formatter } from '@/utils/numbers';
import { DebounceInput } from 'react-debounce-input';
import { SwapButton } from './swap-button';
import { TokenSelector } from './token-selector';
import { useToast } from '@/hooks/use-toast';
import { useSwapTokens } from '@/hooks/useSwitch';
import { Fees } from './fees';
import { useTokenPrices } from '@/hooks/useTokenUSDPrice';
import { isSameChain } from '@/utils';

export default function Swap() {
  const { address } = useAccount();
  const dispatch = useAppDispatch();
  const { toast } = useToast();
  const handleSwapTokens = useSwapTokens();
  const { slippage, auto_slippage, srcToken, destToken } = useAppSelector(
    (state) => state.swapSlice
  );

  const { inputAmount } = useAppSelector((state) => state.swapInputSlice);

  const handleInput = useCallback(
    (event: React.ChangeEvent<HTMLInputElement>) => {
      const value = event.target.value;
      dispatch(setInputAmount({ amount: value }));
    },
    [dispatch]
  );

  const receiver = useMemo(() => {
    if (!address) return null;
    return {
      dstChainTokenOutRecipient: address,
      srcChainOrderAuthorityAddress: address,
      dstChainOrderAuthorityAddress: address,
    };
  }, [address]);

  const fetchChainEstimation = useCallback(
    async (
      params: {
        srcChainId: number;
        destChainId: number;
        tokenIn: string;
        tokenInAmount: string;
        tokenOut: string;
        dstChainTokenOutAmount?: string;
      },
      signal: AbortSignal
    ) => {
      params.tokenInAmount = String(
        parseUnits(inputAmount, (srcToken?.decimals as number) ?? 18)
      );
      const { data } = await axios.post(`/api/quote`, params, { signal });
      return data;
    },
    [inputAmount, srcToken?.decimals]
  );

  const queryParams = useMemo(
    () => ({
      srcChainId: srcToken?.chainId as number,
      destChainId: destToken?.chainId as number,
      tokenIn: srcToken?.address as string,
      tokenInAmount: inputAmount,
      tokenOut: destToken?.address as string,
      slippage: auto_slippage ? undefined : Number(slippage),
      ...(receiver ? receiver : {}),
    }),
    [
      srcToken?.chainId,
      srcToken?.address,
      destToken?.chainId,
      destToken?.address,
      inputAmount,
      auto_slippage,
      slippage,
      receiver,
    ]
  );

  const { data, isLoading, isError, error } = useQuery({
    queryKey: ['chainEstimation', queryParams],
    queryFn: ({ signal }) => fetchChainEstimation(queryParams, signal),
    enabled: !!inputAmount && inputAmount !== '0',
  });

  const outputAmount = useMemo(() => {
    if (!data?.estimation?.tokenOut) {
      return '';
    }
    const { amount, decimals } = data.estimation.tokenOut;
    return convertNumbThousand(Number(formatUnits(amount, decimals)), 12);
  }, [data?.estimation?.tokenOut]);
  const { tokenInUSD, tokenOutUSD } = useTokenPrices(
    srcToken?.address as string,
    destToken?.address as string,
    Number(inputAmount ?? 0),
    Number(
      data?.estimation?.tokenOut
        ? formatUnits(
            data?.estimation?.tokenOut?.amount,
            data?.estimation?.tokenOut?.decimals
          )
        : 0
    ),
    srcToken?.chainId as number,
    destToken?.chainId as number
  );
  const sameChain = useMemo(() => {
    return isSameChain(
      srcToken?.chainId as number,
      destToken?.chainId as number
    );
  }, [srcToken, destToken]);
  useEffect(() => {
    if (isError && error instanceof AxiosError) {
      // Accessing error.response.data in a type-safe way
      if (error.response?.data) {
        toast({
          variant: 'destructive',
          title: 'Uh oh! Something went wrong.',
          description: error.response.data.error,
        });
      } else {
        console.log('No response data');
      }
    }
  }, [error, isError, toast]);

  return (
    <div className="lg:p-4 mt-10 mb-[86px]">
      <div className="w-full mx-auto max-w-lg px-4">
        <div className="flex flex-col gap-4 items-center justify-center">
          <motion.div
            className="relative w-full max-w-xl p-2 md:p-4 rounded-2xl bg-[#23232f] overflow-hidden border border-[#ff6b00]/20 pb-8"
            initial={{ opacity: 0, y: 20 }}
            animate={{ opacity: 1, y: 0 }}
            transition={{ duration: 0.5 }}>
            <motion.div
              className="relative z-10 bg-[#23232f] rounded-xl p-5"
              initial={{ opacity: 0 }}
              animate={{ opacity: 1 }}
              transition={{ delay: 0.2, duration: 0.5 }}>
              <div className="flex justify-between items-center mb-6">
                <div>
                  <motion.h1
                    className="text-3xl font-bold text-[#ff6b00]"
                    initial={{ opacity: 0, x: -20 }}
                    animate={{ opacity: 1, x: 0 }}
                    transition={{ delay: 0.3, duration: 0.5 }}>
                    Exchange
                  </motion.h1>
                  <motion.p
                    className="text-sm text-gray-400 py-1 text-nowrap"
                    initial={{ opacity: 0, x: -20 }}
                    animate={{ opacity: 1, x: 0 }}
                    transition={{ delay: 0.4, duration: 0.5 }}>
                    Trade tokens in an instant
                  </motion.p>
                </div>
                <motion.div
                  className=" flex justify-end space-x-1 lg:space-x-2 w-full"
                  initial={{ opacity: 0, x: 20 }}
                  animate={{ opacity: 1, x: 0 }}
                  transition={{ delay: 0.5, duration: 0.5 }}>
                  <button
                    onClick={() => dispatch(setSwapSetting(true))}
                    className="text-[#ff6b00] hover:text-[#ff6b00] hover:bg-[#ff6b00]/30 focus:bg-[#ff6b00]/40 p-2 rounded-lg">
                    <Settings className="h-5 w-5" />
                  </button>
                </motion.div>
              </div>
            </motion.div>
            <div className="space-y-4">
              <motion.div
                className="space-y-2 bg-[#2c2c3a] p-4 rounded-lg"
                initial={{ opacity: 0, y: 20 }}
                animate={{ opacity: 1, y: 0 }}
                transition={{ delay: 0.6, duration: 0.5 }}>
                <div className="flex justify-between items-center">
                  <label className="text-sm text-gray-400">From</label>
                </div>
                <div className="flex items-center justify-between mt-2">
                  <DebounceInput
                    minLength={1}
                    placeholder={0.0}
                    debounceTimeout={300}
                    min={0}
                    value={inputAmount}
                    type="number"
                    className="text-3xl font-bold bg-transparent  text-white focus:ring-0 p-0 ring-0 outline-none border-0  focus:border-transparent focus-visible:ring-0 w-8/12 pr-2 [appearance:textfield] [&::-webkit-outer-spin-button]:appearance-none [&::-webkit-inner-spin-button]:appearance-none"
                    onChange={handleInput}
                  />

                  <TokenSelector isSource={true} />
                </div>
                <div className="flex justify-between text-sm text-gray-400 mt-2">
                  <span>
                    {sameChain
                      ? formatter.format(tokenInUSD ?? 0)
                      : formatter.format(
                          Number(data?.estimation?.tokenIn?.usd ?? 0)
                        )}
                  </span>
                  <WalletBalance
                    type="INPUT"
                    tokenAddress={srcToken?.address as string}
                    chainId={srcToken?.chainId as number}
                  />
                </div>
              </motion.div>

              <motion.div
                className="flex justify-center"
                initial={{ opacity: 0, scale: 0 }}
                animate={{ opacity: 1, scale: 1 }}
                transition={{ delay: 0.7, duration: 0.5, type: 'spring' }}>
                <button
                  onClick={(e) => {
                    e.preventDefault();
                    handleSwapTokens();
                  }}
                  className="rounded-full p-2 bg-[#ff6b00] text-white hover:bg-[#ff6b00]/90  focus:bg-[#ff6b00]/80 shadow-md">
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    width="24"
                    height="24"
                    viewBox="0 0 24 24"
                    fill="none"
                    stroke="currentColor"
                    strokeWidth="2"
                    strokeLinecap="round"
                    strokeLinejoin="round">
                    <line x1="12" y1="5" x2="12" y2="19"></line>
                    <polyline points="19 12 12 19 5 12"></polyline>
                  </svg>
                </button>
              </motion.div>

              <motion.div
                className="space-y-2 bg-[#2c2c3a] p-4 rounded-lg"
                initial={{ opacity: 0, y: 20 }}
                animate={{ opacity: 1, y: 0 }}
                transition={{ delay: 0.8, duration: 0.5 }}>
                <div className="flex justify-between items-center">
                  <label className="text-sm text-gray-400">To</label>
                </div>
                <div className="flex items-center justify-between mt-2">
                  <div className="flex-grow mr-2 overflow-hidden">
                    {isLoading ? (
                      <SkeletonBox className="w-full h-10" />
                    ) : (
                      <Input
                        readOnly
                        type="text"
                        value={outputAmount}
                        placeholder="0.0"
                        className="text-3xl font-bold bg-transparent border-0 text-white focus:ring-0 p-0 w-full truncate"
                      />
                    )}
                  </div>
                  <TokenSelector isSource={false} />
                </div>
                <div className="flex justify-between text-sm text-gray-400 mt-2">
                  <span>
                    {sameChain
                      ? formatter.format(tokenOutUSD ?? 0)
                      : formatter.format(
                          Number(data?.estimation?.tokenOut?.usd ?? 0)
                        )}
                  </span>
                  <WalletBalance
                    type="OUTPUT"
                    tokenAddress={destToken?.address as string}
                    chainId={destToken?.chainId as number}
                  />
                </div>
              </motion.div>
            </div>
            <motion.div
              className="flex justify-between items-center mt-6 text-sm"
              initial={{ opacity: 0, y: 20 }}
              animate={{ opacity: 1, y: 0 }}
              transition={{ delay: 0.9, duration: 0.4 }}>
              <span className="text-gray-400">Slippage Tolerance</span>
              <span className="text-white">
                {auto_slippage ? 'AUTO' : `${slippage}%`}
              </span>
            </motion.div>
            {data && <Fees data={data} />}
            {data && data.estimation && (
              <motion.div
                className="flex justify-between items-center my-4 text-sm"
                initial={{ opacity: 0, y: 20 }}
                animate={{ opacity: 1, y: 0 }}
                transition={{ delay: 0.9, duration: 0.6 }}>
                <span className="text-gray-400">Minimum received</span>
                <span className="text-white">
                  {convertNumbThousand(
                    Number(
                      formatUnits(
                        data.estimation.tokenOut.minAmount,
                        data.estimation.tokenOut.decimals
                      )
                    )
                  )}{' '}
                  {data.estimation.tokenOut.symbol}
                </span>
              </motion.div>
            )}

            <motion.div
              initial={{ opacity: 0, y: 20 }}
              animate={{ opacity: 1, y: 0 }}
              transition={{ delay: 1, duration: 0.5 }}>
              {!address && (
                <ConnectButton
                  fullWidth
                  className="w-full mt-6 bg-gradient-to-r from-[#ff6b00] to-[#ff3b3b] text-white"
                />
              )}
              {address && <SwapButton data={data} />}
            </motion.div>
          </motion.div>
        </div>
      </div>
    </div>
  );
}
